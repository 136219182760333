.dark {
  .skip-back, .skip-forward, .play, .shuffle, p {
    color: white;
  }
}

.player {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16%;
}

.time-control {
  width: 90%;
  display: flex;
  align-items: center;
  
  input {
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
  }

  p {
    padding: 1rem;
  }
}

svg {
  cursor: pointer;
}

.play-and-shuffle-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
}

.play-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 12rem;
}

.shuffle {
  margin-left: 5px;
}


input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  opacity: 0;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  background: transparent;
  border: none;
}

.track {
  width: 100%;
  height: 1rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.animate-track {
  background-color: rgb(235, 234, 234);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .time-control {
    width: 27rem;
  }

  .play-and-shuffle-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 55px;
  }

  .shuffle {
    margin-left: 30px;
  }

  .play-control {
    width: 15rem;
  }
}

@media screen and (min-width: 1024px) {
  .time-control {
    width: 35rem;
  }
  .play-control {
    width: 18rem;
  }
}

// For landscape oriented tablets
@media screen and (min-width: 768px) and (max-height: 700px) {
  .player {
    margin-bottom: 4%;
  }
}