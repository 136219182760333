.dark {
  nav {
    h1 {
      color: rgb(223, 77, 95);
    }

    button {
      border: 2px solid white;
      color: white;
  
      &:hover {
        border: 2px solid rgb(223, 77, 95);
        color: rgb(223, 77, 95);
        background-color: black;
        }
    }
  }  
}

nav {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 0 24px;
  align-items: center;

  h1 {
    letter-spacing: 2px;
    color: brown;

    @media screen and (min-width: 1024px) {
      font-size: 30px;
    }
  }

  img {
    height: 28px;
    cursor: pointer;

    &:hover {
      transform: scale(1.15);
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
  }

  button {
    background-color: transparent;
    border: 2px solid rgb(65, 65, 65);
    color: black;
    padding: 0.5rem;
    transition: all 0.3s ease;
    margin-right: 30px;
    cursor: pointer;

    &:hover {
    background-color: rgb(65, 65, 65);
    color: white;
    }

    @media screen and (min-width: 1024px) {
      padding: 10px;
      font-size: 18px;
    }
  }
}

.library-icon {
  padding-left: 4px;
}

@media screen and (min-height: 640px) and (max-width: 768px) {
  nav {
    min-height: 76px;
  }
}

@media screen and (min-width: 768px) {
  nav {
    min-height: 10vh;
    justify-content: space-around;
    margin: 0;
  }
}


