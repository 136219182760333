* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html, body, #root, .App {
  height: 100vh;
  max-height: -webkit-fill-available;
}

h2 {
  color: rgba(54, 54, 54, 54);
}

body {
  font-family: 'Lato', sans-serif;
  overflow: hidden;
}

h3, 
h4 {
  font-weight: 400;
  color: rgb(100, 100, 100);
}

.App {
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
}

.dark {
  background-color: rgb(28, 28, 39);

  h2, h3, h4 {
    color: white;
  }
}

.library-active {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .library-active {
    margin-left: 20rem;
  }
}

@media screen and (min-width: 1024px) {
  .library-active {
    margin-left: 25rem;
  }
}


@import "./song";
@import "./player";
@import "./library";
@import "./nav";

