.song-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;

  img {
    width: 11rem;
    border-radius: 50%;
  }

  h2 {
    padding: 10% 2% 3% 2%;
  }

  h3 {
    font-size: 1rem;
  }
}

@media screen and (min-height: 640px) and (max-width: 768px) {
  .song-container {
    min-height: 400px;

    img {
      width: 12.5rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .song-container {
    min-height: 60vh;

    img {
      width: 15rem;

      @media screen and (min-width: 1024px) {
        width: 18rem;
      }
    }

    h2 {
      padding: 4rem 1rem 1rem 1rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .song-container {
    min-height: 60vh;

    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 20px;
    }
  }
}

// For landscape oriented mobile
@media screen and (max-height: 420px) {
  .song-container {
    min-height: 30vh;

    img {
      display: none;
    }
  } 
}

  