.dark {
  .library {
    background-color: rgb(28, 28, 39);
    box-shadow: 2px 2px 50px rgba(0, 0, 0, 1);
  }

  .library-song {
    &:hover {
      background-color: rgba(219, 102, 116, 0.288);
    }
  }

  .selected {
    background-color: rgb(200, 68, 83);
    &:hover {
      background-color: rgb(200, 68, 83);
    }
  }

  .close-library {
    color: white;
  }

  *{
    scrollbar-color: rgba(155, 155, 155, 0.628) transparent;
  }

  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.628) transparent;

  }

}

.library {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 2px 2px 50px rgb(204, 204, 204);
  transform: translateX(-100%);
  transition: all 0.5s ease;
  opacity: 0;
  h2 {
    padding: 2rem;
  }
}

// Makes the library songs scrollable but the library header stick to the top
.library-songs {
  height: 100%;
  overflow: scroll;
  overflow-y: overlay;
  overflow-x: hidden;

  // Adds spacing to the bottom of the scroll section to make sure the last song isn't cut off
  padding-bottom: 116px;
}

.library-song {
  display: flex;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
  cursor: pointer;
  transition: background 0.5s ease;

  img {
    width: 30%;
  }

  &:hover {
    background-color: rgba(167, 32, 32, 0.181);
  }
}

.song-description {
  padding-left: 1rem;

  h3 {
    font-size: 1rem;
  }
  
  h4 {
    font-size: 0.7rem;
  }
}

*{
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.8) transparent;
}

*::-webkit-scrollbar {
  width: 5px;
}


*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.8);
  border-radius: 20px;
}

.selected {
  background-color:rgba(167, 32, 32, 0.363);
  &:hover {
    background-color: rgba(167, 32, 32, 0.363);
  }
}

.library-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-library {
  margin-right: 24px;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    font-size: 23px;

    &:hover {
      transform: scale(1.3);
    }
  }
  
}

.active-library {
  transform: translateX(0);
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .library {
    width: 20rem;
  }
}

@media screen and (min-width: 1024px) {
  .library {
    width: 25rem;
  }
}